import React from "react"
import ReactGA from "react-ga"

import "./layout.css"
import "./styles.css"

ReactGA.initialize("UA-165889437-1")

const Layout = ({ children }) => <main>{children}</main>

export default Layout
